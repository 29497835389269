import request from '@/api/request.js'

/**
 * @Description: 库存记录
 */

// 分页查询
export function pageWmsStockHistory(data) {
    return request({
        url: '/api/wmsStockHistory/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsStockHistory(data) {
    return request({
        url: '/api/wmsStockHistory/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsStockHistory(data) {
    return request({
        url: '/api/wmsStockHistory/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsStockHistory(data) {
    return request({
        url: '/api/wmsStockHistory/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsStockHistory(data) {
    return request({
        url: '/api/wmsStockHistory/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsStockHistory(data) {
    return request({
        url: '/api/wmsStockHistory/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsStockHistory(data) {
    return request({
        url: '/api/wmsStockHistory/batchDelete',
        method: 'POST',
        data
    })
}


<template>
  <div>
    <div class="contents">
      <submenu curbg=1></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <el-form-item label="来源单号" style="margin-bottom:10px">

                  <el-input v-model="searchForm.erpId" clearable  style="width:190px"
                    placeholder="请输入来源单号"></el-input>
                </el-form-item>

                <el-form-item label="单据编号" style="margin-bottom:10px">

                  <el-input v-model="searchForm.inOutOrderCode" clearable  style="width:190px"
                    placeholder="请输入单据编号"></el-input>
                </el-form-item>

                <el-form-item label="商品" style="margin-bottom:10px">

                  <el-input v-model="searchForm.skuName" clearable  style="width:190px"
                    placeholder="请输入商品"></el-input>
                </el-form-item>

                <el-form-item label="商品编码" style="margin-bottom:10px">

                  <el-input v-model="searchForm.skuCode" clearable  style="width:190px"
                    placeholder="请输入商品编码"></el-input>
                </el-form-item>

                <el-form-item label="仓库" style="margin-bottom:10px">

                  <el-input v-model="searchForm.warehouseName" clearable  style="width:190px"
                    placeholder="请输入仓库"></el-input>
                </el-form-item>

                <el-form-item label="货位" style="margin-bottom:10px">

                  <el-input v-model="searchForm.binName" clearable  style="width:190px"
                    placeholder="请输入货位"></el-input>
                </el-form-item>

                <el-form-item label="客户" style="margin-bottom:10px">

                  <el-input v-model="searchForm.customerName" clearable  style="width:190px"
                    placeholder="请输入客户"></el-input>
                </el-form-item>

                <el-form-item label="库存类型" style="margin-bottom:10px">

                    <el-select v-model="searchForm.type"
                    clearable
                    filterable
                    placeholder="请选择库存类型">
                        <el-option
                              v-for="item in enums['WmsStockHistoryTypeEnum'].filter(it => it.searchable)"
                              :key="item.value"
                              :label="item.name"
                              :value="item.value">
                            </el-option>
                    </el-select>
                </el-form-item>

                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>

            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>


        <vxe-column
            field="erpId"
            title="来源单号"
            min-width="180"
        >
          <template slot-scope="scope">
                {{scope.row.erpId}}
          </template>
        </vxe-column>
        <vxe-column
            field="inOutOrderCode"
            title="单据编号"
            min-width="180"
        >
          <template slot-scope="scope">
                {{scope.row.inOutOrderCode}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuName"
            title="商品"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuName}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuSpec"
            title="规格"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuSpec}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuCode"
            title="商品编码"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuCode}}
          </template>
        </vxe-column>
        <vxe-column
            field="quantity"
            title="数量"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.quantity}}
          </template>
        </vxe-column>
        <vxe-column
            field="unitName"
            title="库存单位"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.unitName}}
          </template>
        </vxe-column>
        <vxe-column
            field="warehouseName"
            title="仓库"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.warehouseName}}
          </template>
        </vxe-column>
        <vxe-column
            field="binName"
            title="货位"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.binName}}
          </template>
        </vxe-column>
        <vxe-column
            field="customerName"
            title="客户"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.customerName}}
          </template>
        </vxe-column>
        <vxe-column
            field="type"
            title="库存类型"
            min-width="80"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsStockHistoryTypeEnum'][scope.row.type]}}
          </template>
        </vxe-column>
        <vxe-column
            field="remark"
            title="备注"
            min-width="50"
        >
          <template slot-scope="scope">
                {{scope.row.remark}}
          </template>
        </vxe-column>

        <vxe-column title="操作" fixed="right" width="160">
            <template slot-scope="scope">
                  <el-button class="c-button" @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>


             </template>
        </vxe-column>

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <stockhistory-add ref="wmsStockHistoryAdd" @success="getTopSearch"></stockhistory-add>
    <stockhistory-detail ref="wmsStockHistoryDetail" @success="getTopSearch"></stockhistory-detail>
    <stockhistory-edit ref="wmsStockHistoryEdit" @success="getTopSearch"></stockhistory-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import submenu from "@/components/submenu"
import backImg from "@/assets/logofang.png"
import stockhistoryAdd from "./wmsStockHistoryAdd"
import stockhistoryDetail from "./wmsStockHistoryDetail"
import stockhistoryEdit from "./wmsStockHistoryEdit"

import { pageWmsStockHistory, detailWmsStockHistory, delWmsStockHistory, batchDeleteWmsStockHistory } from "@/api/wms/wmsStockHistory"
import { getEnums } from "@/api/common"
import { listWmsInOutOrder } from "@/api/wms/wmsInOutOrder"


let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsStockHistory',
  components: { submenu, stockhistoryAdd, stockhistoryDetail, stockhistoryEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      backImg: backImg,
      enums: {
        WmsStockHistoryTypeEnum: [],
      },
      enumMaps: {},
        optionsOfWmsInOutOrder: [],

      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要批量删除的记录")
        }
        this.$confirm('确定要删除这'+ this.multipleSelection.length +'条数据吗，一旦删除不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDeleteWmsStockHistory(param).then(res => {
                    if(res.code===200) {
                        this.$message.success(res.message)
                        this.getTopSearch()
                    } else {
                        this.$message.error(res.message)
                    }
                 })
        }).catch(err=>{})
    },
    handleAdd() {
      this.$refs.wmsStockHistoryAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsStockHistoryDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsStockHistoryEdit.onOpen(row)
    },
    handleSelectionChange(val) {
        console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },

    listWmsInOutOrder(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsInOutOrder(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsInOutOrder = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      pageWmsStockHistory(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  created() {
    this.initEnums()
    this.listWmsInOutOrder()

    this.getTopSearch()
  },
  mounted(){
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.el-select {
    width: 190px;
}
.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.tbBtn {
  margin-right:10px;
}
.buttons {
}
</style>